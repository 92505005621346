<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";



  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,


    },
    data() {
      return {
        title: "成绩查询列表",
        items: [{
            text: "考试报名"
          },
          {
            text: "报名记录",
            active: true
          }
        ],
        defaultvalue: 3,
        currentpage: 3
      };
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <h4 class="check-table-top pb-3 border-dash" style="justify-content: flex-start">
              <b-dropdown size="sm" class="mr-2  " variant="outline-info"  style="min-width:8%">
                <template v-slot:button-content>
                  所有考试类别 <i class="mdi mdi-chevron-down"></i>
                </template>
                <b-dropdown-item-button> 所有考试类别</b-dropdown-item-button>
                <b-dropdown-item-button>全国职业（执业）资格考试</b-dropdown-item-button>
                <b-dropdown-item-button>全国专业技术资格考试</b-dropdown-item-button>
              </b-dropdown>
              <b-dropdown size="sm" class="mr-2  " variant="outline-info" style="min-width:5%" >
                <template v-slot:button-content>
                  2021年
                  <i class="mdi mdi-chevron-down"></i>
                </template>
                <b-dropdown-item-button>2021年</b-dropdown-item-button>
                <b-dropdown-item-button>2020年</b-dropdown-item-button>
                <b-dropdown-item-button>2019年</b-dropdown-item-button>
              </b-dropdown>
              <button type="button" class="btn btn-info h30 flexList"><i class="iconfont mr-2 icon-mb-search"></i>查询</button>
            </h4>
            <div class="table-responsive border mt-3">
              <table class="table table-has-bg light-table table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th  style="width:4%">序号</th>
                    <th style="width:15%">考试类别</th>
                    <th>考试名称</th>
                    <th style="width:5%">年份</th>
                    <th style="width:8%">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>全国职业(执业）资格考试</td>
                    <td>2021年四川省高级人民法院公开招聘聘用制书记员 <span class="text-danger">已报名</span></td>
                    <td>2021</td>
                    <td class="table-btn"><a href=""><button type="button" class="btn btn-info h30"><i
                          class="iconfont icon-come-l align-middle mr-1"></i>进入查询</button></a></td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>全国职业(执业）资格考试</td>
                    <td>2021年四川省高级人民法院公开招聘聘用制书记员 <span class="text-danger">已报名</span></td>
                    <td>2021</td>
                    <td class="table-btn"><button type="button" class="btn btn-info h30"><i
                          class="iconfont icon-come-l align-middle mr-1"></i>进入查询</button></td>
                  </tr>
                  <tr>
                    <th scope="row">1</th>
                    <td>全国职业(执业）资格考试</td>
                    <td>四川电信实业集团有限责任公司关于直属事业单位2021年12月考核招聘博士、副高及以上高层次人才 <span class="text-danger">已报名</span></td>
                    <td>2021</td>
                    <td class="table-btn"><button type="button" class="btn btn-info h30"><i
                          class="iconfont icon-come-l align-middle mr-1"></i>进入查询</button></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>

  </Layout>
</template>
